import React from 'react';
import ReactGA from 'react-ga';
import { ModalHotspotProps, HotspotState } from '../types/hotspot';
import './ModalHotspot.css';
import { stopAutoRotate, startAutoRotate, changeCamera, getNameById } from '../libs/marzipano';
/*import modalHotspot from '../icon/gallery.svg';
import modalHover from '../icon/gallery-hover.svg';
import modalSingleHotspot from '../icon/modal-single.svg';
import modalSingleHover from '../icon/modal-single-hover.svg';*/
import { ReactComponent as ModalSVG } from '../icon/type=gallery.svg';
import { ReactComponent as VideoSVG } from '../icon/type=video.svg';
import { ReactComponent as T3DSVG } from '../icon/type=3d.svg';
import { ReactComponent as NewSVG } from '../icon/new.svg';
import { ReactComponent as NeuSVG } from '../icon/neu.svg';
import { GlobalVars } from '../libs/utils';

export default class ModalHotspot extends React.Component<ModalHotspotProps> {
  state: HotspotState = {
    active: false,
    hovered: false,
  };

  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('closeAllHotspots', () => {
      this.mounted && this.setState({ active: false });
    });
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  act = (hKey: string) => {
    if (this.props.hKey === hKey) this.activate();
  };
  deact = (hKey: string) => {
    if (this.props.hKey === hKey) {
      this.mounted && this.setState({ active: false });
      if (this.props.mode === 'normal') {
        startAutoRotate();
      }
    }
  };

  componentDidUpdate = (prevProps: ModalHotspotProps) => {
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.off('activated', this.act);
      GlobalVars.socket.off('deactivated', this.deact);
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, duration, () => {
          if (this.props.mode === 'normal') {
            this.props.changeMode('paused');
          }
          setTimeout(() => {
            const label = this.props.info.label ? this.props.info.label : this.props.info.title;
            this.props.changeBarLabel(label);
            this.mounted && this.setState({ active: true });
            this.props.unblockArrows();
          }, 500);
        });
      } else {
        this.mounted && this.setState({ active: false });
      }
    }
    if (prevProps.mode !== this.props.mode && this.props.mode === 'normal') {
      this.mounted && this.setState({ active: false });
    }
  };

  activate = () => {
    const closeAllEvent = new CustomEvent('closeAllHotspots', {
      detail: {},
    });
    window.dispatchEvent(closeAllEvent);
    ReactGA.event({
      category: 'Modal Hotspot',
      action: 'Clicked',
      label: `${getNameById(this.props.sceneId)}[${this.props.info.title}]`,
    });
    stopAutoRotate();
    changeCamera(
      this.props.info.yaw,
      this.props.info.pitch,
      undefined,
      this.props.info.cameraDuration,
      () => {
        const stopDescriptionEvent = new CustomEvent('stopDescription', {
          detail: {},
        });
        window.dispatchEvent(stopDescriptionEvent);
        setTimeout(() => {
          this.mounted && this.setState({ active: true });
          if (this.props.mode === 'autoplay') {
            this.props.changeMode('paused');
          }
        }, 500);
      },
    );
    if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
      GlobalVars.socket.emit('activate', this.props.hKey);
    }
  };

  render() {
    const activeClass = this.state.active ? 'active' : '';
    const Content = require(`../templates/modals/${this.props.info.template}.template.tsx`).default;

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let compareClass = '';
    if (this.props.left) {
      compareClass = 'left ';
    }
    if (this.props.right) {
      compareClass = 'right ';
    }

    const iconStyle = mobile ? { width: '24px', height: '24px' } : {};

    let hotspotIcon = (
      <ModalSVG
        style={iconStyle}
        onMouseEnter={() => {
          this.mounted && this.setState({ hovered: true });
        }}
        onMouseLeave={() => {
          this.mounted && this.setState({ hovered: false });
        }}
      />
    );
    if (this.props.info.videoSrc || this.props.info.template === 'modalVideoGaleria') {
      hotspotIcon = (
        <VideoSVG
          style={iconStyle}
          onMouseEnter={() => {
            this.mounted && this.setState({ hovered: true });
          }}
          onMouseLeave={() => {
            this.mounted && this.setState({ hovered: false });
          }}
        />
      );
    }
    if (this.props.info.template === 'modalViewer3d') {
      hotspotIcon = (
        <T3DSVG
          style={iconStyle}
          onMouseEnter={() => {
            this.mounted && this.setState({ hovered: true });
          }}
          onMouseLeave={() => {
            this.mounted && this.setState({ hovered: false });
          }}
        />
      );
    }

    return (
      <div>
        <div
          key={this.props.hKey}
          className={'hotspot-modal ' + compareClass + this.props.sceneId}
          onClick={() => this.activate()}
          onTouchStart={() => {
            stopAutoRotate();
          }}
          onTouchEnd={() => {
            this.mounted && this.setState({ active: false });
            if (this.props.mode === 'normal') {
              startAutoRotate();
            }
            if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
              GlobalVars.socket.emit('deactivate', this.props.hKey);
            }
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          {this.props.info.modelData?.new && this.props.info.modelData?.new !== 'neu' && (
            <NewSVG
              className={this.state.hovered ? 'new-label hover' : 'new-label'}
              style={mobile ? { width: '24px' } : {}}
            />
          )}
          {this.props.info.modelData?.new === 'neu' && (
            <NeuSVG
              className={this.state.hovered ? 'new-label hover' : 'new-label'}
              style={mobile ? { width: '24px' } : {}}
            />
          )}
          {/*this.props.info.modelData?.new && (
            <div className="new-label">{this.props.info.modelData.new}</div>
          )*/}
          {hotspotIcon}
        </div>
        <div className={'modal-container ' + activeClass}>
          <div
            className="background"
            onClick={() => {
              this.mounted && this.setState({ active: false });
              if (this.props.mode === 'normal') {
                startAutoRotate();
              }
              if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
                GlobalVars.socket.emit('deactivate', this.props.hKey);
              }
            }}
          ></div>
          <Content
            info={this.props.info}
            navBarEnabled={this.props.navBarEnabled}
            mode={this.props.mode}
            changeMode={this.props.changeMode}
            close={() => {
              this.mounted && this.setState({ active: false });
              if (this.props.mode === 'normal') {
                startAutoRotate();
              }
              if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
                GlobalVars.socket.emit('deactivate', this.props.hKey);
              }
            }}
            active={this.state.active}
          />
        </div>
      </div>
    );
  }
}
