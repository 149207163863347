import React, { createRef } from 'react';
import ReactGA from 'react-ga';
import './PointAndAskForm.css';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import {
  changeCamera,
  getNameById,
  mousePositionToMarzipanoCoords,
  position,
} from '../libs/marzipano';
import { encodeHash, isEmail } from '../libs/utils';
import { SuccessMessage } from '../types/marzipano';

interface PointAndAskFormState {
  active: boolean;
  cursorX: number;
  cursorY: number;
  email: string | undefined;
  missingFields: boolean;
  success: boolean;
}

interface PointAndAskFormProps {
  title: string;
  clientEmail: string;
  success: SuccessMessage;
  selectedSceneId: string | string[];
  timelapseIndex: number;
  mappingFile: string;
  sector: string;
  addHotspot: CallableFunction;
  toggle: CallableFunction;
  defaultEmail: string;
  changeDefaultEmail: CallableFunction;
  language: string | undefined;
  flat: boolean;
}

export default class PointAndAskForm extends React.Component<PointAndAskFormProps> {
  cursorX: number = 0;
  cursorY: number = 0;

  start: Date = new Date();
  end: Date = new Date();

  emailRef = createRef<HTMLInputElement>();
  nameRef = createRef<HTMLInputElement>();
  textAreaRef = createRef<HTMLTextAreaElement>();

  state: PointAndAskFormState = {
    active: false,
    cursorX: this.cursorX,
    cursorY: this.cursorY,
    email: undefined,
    missingFields: false,
    success: false,
  };

  componentWillUnmount = () => {
    const pano = document?.getElementById('pano');
    if (pano) {
      pano.ondblclick = () => {};
    }
  };

  componentDidMount = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    if (mobile) {
      const pano = document?.getElementById('pano');
      if (pano) {
        pano.ontouchstart = () => {
          this.start = new Date();
        };
        pano.ontouchmove = () => {
          this.start = new Date();
        };
        pano.ontouchend = (e) => {
          e.preventDefault();
          this.end = new Date();
          if (this.end.getTime() - this.start.getTime() < 500) {
            this.setState({ active: false, missingFields: false, success: false });
          } else {
            const touch = e.touches[0] || e.changedTouches[0];
            const x = touch.clientX;
            const y = touch.clientY;
            const mousePos = JSON.parse('{' + mousePositionToMarzipanoCoords(x, y) + '}');
            const offset = this.props.flat ? position().fov / 10 : position().fov / 8;
            changeCamera(mousePos.yaw, mousePos.pitch - offset, undefined, 1000, () => {
              this.setState({
                active: true,
                cursorX: x,
                cursorY: y,
              });
            });
          }
        };
      }
    } else {
      onmousemove = (e) => {
        this.cursorX = e.clientX;
        this.cursorY = e.clientY;
      };
      this.setState({ cursorX: this.cursorX, cursorY: this.cursorY });
      const pano = document?.getElementById('pano');
      if (pano) {
        pano.ondblclick = () => {
          const x = this.cursorX.valueOf();
          const y = this.cursorY.valueOf();
          const mousePos = JSON.parse('{' + mousePositionToMarzipanoCoords(x, y) + '}');
          const offset = this.props.flat ? position().fov / 10 : position().fov / 8;
          changeCamera(mousePos.yaw, mousePos.pitch - offset, undefined, 1000, () => {
            this.setState({
              active: true,
              cursorX: x,
              cursorY: y,
            });
          });
        };

        pano.onclick = () => {
          if (this.state.success) {
            this.props.toggle(false);
          }
          this.setState({ active: false, missingFields: false, success: false });
        };
      }
    }
  };

  componentDidUpdate = () => {
    onmousemove = (e) => {
      this.cursorX = e.clientX;
      this.cursorY = e.clientY;
    };
  };

  getLink = () => {
    const pos = position();
    const scene = Array.isArray(this.props.selectedSceneId)
      ? this.props.selectedSceneId[this.props.timelapseIndex]
      : this.props.selectedSceneId;
    ReactGA.event({
      category: 'Share Link',
      action: 'Clicked',
      label: scene,
    });
    const timelapseIndex = Array.isArray(this.props.selectedSceneId)
      ? this.props.timelapseIndex
      : -1;
    const code = encodeHash(
      this.props.mappingFile.split('.')[0],
      this.props.sector,
      scene,
      pos.yaw,
      pos.pitch + 0.2,
      pos.fov,
      String(timelapseIndex),
      'test',
      'true',
    );

    return window.location.href + code;
  };

  send = (from: string, message: string, question: string) => {
    fetch('http://sendmail.monade.tech/sendmail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: this.props.clientEmail,
        email: from,
        subject: 'Pergunta de um visitante',
        message: message,
        tid: 'd-d88efab6754940efa00092f08033e655',
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then((response) => {
        this.setState({ success: true });
        let pos = position();
        pos.pitch += 0.2;
        this.props.addHotspot(pos, question);
        // setTimeout(() => this.setState({ info: "" }), 3000)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  sendEmail = () => {
    const from = this.emailRef.current?.value;
    const body = this.textAreaRef.current?.value ? this.textAreaRef.current.value : null;
    const link = this.getLink();
    if (from && isEmail(from) && body) {
      const message = `O visitante com o email: ${from} colocou a questão: "${body}" sobre o seguinte local: ${link}`;
      this.props.changeDefaultEmail(from);
      this.send(from, message, body);
      this.setState({ missingFields: false });
      const scene = Array.isArray(this.props.selectedSceneId)
        ? getNameById(this.props.selectedSceneId[this.props.timelapseIndex])
        : getNameById(this.props.selectedSceneId);
      ReactGA.event({
        category: 'Point and Ask',
        action: 'Sent Email',
        label: `${from}->"${body}":${this.props.sector} ${scene}`,
      });
    } else {
      this.setState({ missingFields: true });
    }
  };

  render = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let textareaPlaceholder = 'o que deseja saber?';
    let sendBtnLbl = 'ENVIAR';
    let closeSuccessLbl = 'FECHAR';
    switch (this.props.language) {
      case 'English':
        textareaPlaceholder = 'what would you like to know?';
        sendBtnLbl = 'SEND';
        closeSuccessLbl = 'CLOSE';
        break;

      default:
        textareaPlaceholder = 'o que deseja saber?';
        sendBtnLbl = 'ENVIAR';
        closeSuccessLbl = 'FECHAR';
        break;
    }

    const active = this.state.active ? ' active' : '';

    const missingFieldsStyle = this.state.missingFields ? { boxShadow: '0 0 3px #CC0000' } : {};

    if (mobile) {
      if (this.state.success) {
        return (
          <div className="mobile-pa-success">
            <h1>{this.props.success.title}</h1>
            <p>{this.props.success.message}</p>
          </div>
        );
      } else {
        return (
          <div className={'mobile-pa-form' + active}>
            <div className="mobile-pa-header">
              <h1>{this.props.title}</h1>
              <div
                className="close"
                onClick={() => {
                  this.setState({ active: false, missingFields: false });
                }}
              >
                <CloseSVG
                  onClick={() => {
                    this.setState({ active: false, missingFields: false });
                  }}
                />
              </div>
            </div>
            <div className="mobile-pa-body">
              <div className="info">
                <input
                  style={this.emailRef.current?.value ? {} : missingFieldsStyle}
                  ref={this.emailRef}
                  type="email"
                  name="pa-email"
                  placeholder="email"
                  defaultValue={this.props.defaultEmail}
                />
              </div>
              <textarea
                style={this.textAreaRef.current?.value ? {} : missingFieldsStyle}
                ref={this.textAreaRef}
                placeholder={textareaPlaceholder}
                required
              />
            </div>
            <div className="mobile-pa-footer">
              <input
                type="submit"
                value={sendBtnLbl}
                onClick={(e) => {
                  e.preventDefault();
                  this.sendEmail();
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      if (this.state.success) {
        return (
          <div className="point-ask-success">
            <h1>{this.props.success.title}</h1>
            <p>{this.props.success.message}</p>
            <div className="close-btn">{closeSuccessLbl}</div>
          </div>
        );
      } else {
        return (
          <div
            className={'point-ask-container' + active}
            style={{
              top: '50vh',
              left: '50%',
              transform: 'translate(-50%, -80%)',
            }}
          >
            <div className="point-ask-header">
              <h1>{this.props.title}</h1>
              <div
                className="close"
                onClick={() => {
                  this.setState({ active: false, missingFields: false });
                }}
              >
                <CloseSVG
                  onClick={() => {
                    this.setState({ active: false, missingFields: false });
                  }}
                />
              </div>
            </div>
            <div className="point-ask-body">
              <div className="info">
                <input
                  style={this.emailRef.current?.value ? {} : missingFieldsStyle}
                  ref={this.emailRef}
                  type="email"
                  name="pa-email"
                  placeholder="email"
                  defaultValue={this.props.defaultEmail}
                />
              </div>
              <textarea
                style={this.textAreaRef.current?.value ? {} : missingFieldsStyle}
                ref={this.textAreaRef}
                placeholder={textareaPlaceholder}
                required
              />
            </div>
            <div className="point-ask-footer">
              <input
                type="submit"
                value={sendBtnLbl}
                onClick={(e) => {
                  e.preventDefault();
                  this.sendEmail();
                }}
              />
            </div>
            <div className="arrow-down"></div>
          </div>
        );
      }
    }
  };
}
