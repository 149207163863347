import React from 'react';
import ReactGA from 'react-ga';
import { HotspotState, ImgHotspotProps } from '../types/hotspot';
import './ImgHotspot.css';
import { stopAutoRotate, startAutoRotate, changeCamera, getNameById } from '../libs/marzipano';
/*import selectedOval from '../icon/oval.svg';
import mediaHotspot from '../icon/media.svg';
import mediaHover from '../icon/media-hover.svg';*/
import { ReactComponent as ImgSVG } from '../icon/type=media.svg';
import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { GlobalVars } from '../libs/utils';

interface ImgState extends HotspotState {
  fullscreen: boolean;
}

export default class ImgHotspot extends React.Component<ImgHotspotProps> {
  state: ImgState = {
    active: false,
    fullscreen: false,
  };

  mounted: boolean = false;

  componentDidMount = () => {
    this.mounted = true;
    window.addEventListener('closeAllHotspots', () => {
      this.mounted && this.setState({ active: false });
    });
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  act = (hKey: string) => {
    if (this.props.hKey === hKey) this.activate();
  };
  deact = (hKey: string) => {
    if (this.props.hKey === hKey) this.deactivate();
  };

  componentDidUpdate = (prevProps: ImgHotspotProps) => {
    if (GlobalVars.room !== undefined && GlobalVars.socket && !GlobalVars.controller) {
      GlobalVars.socket.off('activated', this.act);
      GlobalVars.socket.off('deactivated', this.deact);
      GlobalVars.socket.on('activated', this.act);
      GlobalVars.socket.on('deactivated', this.deact);
    }
    if (prevProps.animate !== this.props.animate) {
      if (this.props.animate) {
        const duration = this.props.animationDuration ? this.props.animationDuration : 1000;
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, duration, () => {
          setTimeout(() => {
            if (this.props.mode === 'normal') {
              this.props.changeMode('paused');
            }
            const label = this.props.info.label ? this.props.info.label : this.props.info.text;
            this.props.changeBarLabel(label);
            this.mounted && this.setState({ active: true });
            this.props.unblockArrows();
          }, 500);
        });
      } else {
        this.mounted && this.setState({ active: false });
      }
    }
    if (prevProps.mode !== this.props.mode && this.props.mode === 'normal') {
      this.mounted && this.setState({ active: false });
    }
  };

  activate = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const closeAllEvent = new CustomEvent('closeAllHotspots', {
      detail: {},
    });
    window.dispatchEvent(closeAllEvent);
    if (!this.state.active) {
      ReactGA.event({
        category: 'Image Hotspot',
        action: 'Clicked',
        label: `${getNameById(this.props.sceneId)}[${this.props.info.text}]`,
      });
      stopAutoRotate();
      if (mobile) {
        changeCamera(this.props.info.yaw, this.props.info.pitch, undefined, undefined, () => {
          const stopDescriptionEvent = new CustomEvent('stopDescription', {
            detail: {},
          });
          window.dispatchEvent(stopDescriptionEvent);
          setTimeout(() => {
            this.mounted && this.setState({ active: true });
            if (this.props.mode === 'autoplay') {
              this.props.changeMode('paused');
            }
          }, 500);
        });
      } else {
        const stopDescriptionEvent = new CustomEvent('stopDescription', {
          detail: {},
        });
        window.dispatchEvent(stopDescriptionEvent);
        this.mounted && this.setState({ active: true });
        if (this.props.mode === 'autoplay') {
          this.props.changeMode('paused');
        }
      }
      if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
        GlobalVars.socket.emit('activate', this.props.hKey);
      }
    }
  };

  deactivate = () => {
    this.mounted && this.setState({ active: false });
    if (this.props.mode === 'normal') {
      startAutoRotate();
    }
    if (GlobalVars.room !== undefined && GlobalVars.socket && GlobalVars.controller) {
      GlobalVars.socket.emit('deactivate', this.props.hKey);
    }
  };

  render() {
    const activeClass = this.state.active ? 'active' : '';
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const iconStyle = mobile ? { width: '32px', height: '32px' } : {};
    return (
      <div>
        <div
          style={{ zIndex: 99 }}
          key={this.props.hKey}
          className={'hotspot-img ' + this.props.sceneId}
        >
          <ImgSVG
            onMouseEnter={() => {
              if (!mobile) this.activate();
            }}
            onMouseLeave={() => {
              if (!mobile) this.deactivate();
            }}
            onClick={() => {
              if (mobile) {
                this.activate();
              }
            }}
            style={iconStyle}
          />
          {mobile ? null : (
            <div className={'content ' + activeClass}>
              <h3>{this.props.info.text}</h3>
              <div className="image-container">
                <img src={this.props.info.imgSrc} alt={this.props.info.text}></img>
              </div>
            </div>
          )}
        </div>
        {mobile && this.state.fullscreen ? (
          <div className="fullscreen-container">
            <CloseSVG
              onClick={() => {
                this.mounted && this.setState({ fullscreen: false });
              }}
            />
            <img src={this.props.info.imgSrc} alt={this.props.info.text}></img>
          </div>
        ) : null}
        {mobile && !this.state.fullscreen ? (
          <div className={'img-hotspot-container ' + activeClass}>
            <div
              className="background"
              onClick={() => {
                this.deactivate();
              }}
            ></div>
            <div className="mobile-content">
              <h3>
                {this.props.info.text}
                <div className="close">
                  <CloseSVG
                    onClick={() => {
                      this.mounted && this.setState({ active: false });
                      if (this.props.mode === 'normal') {
                        startAutoRotate();
                      }
                    }}
                  />
                </div>
              </h3>
              <div className={'image-container'}>
                <img
                  src={this.props.info.imgSrc}
                  alt={this.props.info.text}
                  onClick={() => {
                    this.mounted && this.setState({ fullscreen: true });
                  }}
                ></img>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
