import React from 'react';
import { Modal1State } from '../../types/modals';
import './modalGaleria.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as LeftSVG } from '../../icon/left.svg';
import { ReactComponent as RightSVG } from '../../icon/right.svg';
import { ReactComponent as GallerySVG } from '../../icon/collections.svg';

export default class Modal1 extends React.Component<ModalHotspotProps> {
  state: Modal1State = {
    selected: this.props.info.images[0].url,
    selectedObs: this.props.info.images[0].obs,
    selectors: this.props.info.images.map((i, index) => {
      return index === 0 ? 'active' : '';
    }),
    selectedIndex: 0,
    gallerySelect: false,
  };

  select(src: string, obs: string, index: number) {
    if (this.props.mode === 'autoplay') {
      this.props.changeMode('paused');
    }
    const tmp = this.state.selectors.map(() => {
      return '';
    });
    tmp[index] = 'active';
    this.setState({ selected: src, selectedObs: obs, selectors: tmp });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.setState({ selectedIndex: index, gallerySelect: false });
    }
  }

  selectRight = () => {
    const next =
      this.state.selectedIndex === this.props.info.images.length - 1
        ? 0
        : this.state.selectedIndex + 1;
    this.setState({
      selected: this.props.info.images[next].url,
      selectedObs: this.props.info.images[next].obs,
      selectedIndex: next,
    });
  };

  selectLeft = () => {
    const next =
      this.state.selectedIndex === 0
        ? this.props.info.images.length - 1
        : this.state.selectedIndex - 1;
    this.setState({
      selected: this.props.info.images[next].url,
      selectedObs: this.props.info.images[next].obs,
      selectedIndex: next,
    });
  };

  gallerySelect = () => {
    const select = !this.state.gallerySelect;
    this.setState({ gallerySelect: select });
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const images = this.props.info.images.map((image, index) => {
      return (
        <div
          key={`img-${index}`}
          className={'selector-img-container ' + this.state.selectors[index]}
          onClick={() => this.select(image.url, image.obs, index)}
        >
          <img className={'selector-img'} src={image.url} alt=""></img>
          <div className="border"></div>
        </div>
      );
    });

    const mobileActiveGallery = this.state.gallerySelect ? 'active' : '';
    const mobileDescription = this.state.gallerySelect
      ? 'Escolha a imagem'
      : this.state.selectedObs;

    const navBar = this.props.navBarEnabled && !window.orientation ? 'navBar-' : '';

    if (mobile) {
      return (
        <div className="mobile-modal-galeria">
          {this.state.gallerySelect ? (
            <div className="mobile-selector">{images}</div>
          ) : (
            <div className="main">
              <LeftSVG onClick={() => this.selectLeft()} />
              <img src={this.state.selected} alt=""></img>
              <RightSVG onClick={() => this.selectRight()} />
            </div>
          )}
          <div className="footer">
            <GallerySVG className={mobileActiveGallery} onClick={() => this.gallerySelect()} />
            <p>{mobileDescription}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className={navBar + 'modal'}>
          <img src={this.state.selected} alt=""></img>
          <p>{this.state.selectedObs}</p>
          <div className="selector">{images}</div>
        </div>
      );
    }
  }
}
