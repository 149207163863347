import React from 'react';
import './modalDetalhada.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { Modal1State } from '../../types/modals';
import { DecorData } from '../../types/decor';
import { ReactComponent as LeftSVG } from '../../icon/left.svg';
import { ReactComponent as RightSVG } from '../../icon/right.svg';
import { ReactComponent as GallerySVG } from '../../icon/collections.svg';
import { ReactComponent as ImageSVG } from '../../icon/image.svg';
import { ReactComponent as InfoSVG } from '../../icon/info.svg';

interface ModalDetProps extends ModalHotspotProps {
  close: CallableFunction;
}

interface DetState extends Modal1State {
  mode: string;
}

export default class Modal1 extends React.Component<ModalDetProps> {
  decorData: DecorData = this.props.info.decorData
    ? require(`../../JSON/${this.props.info.decorData.path}`)[this.props.info.decorData.index]
    : undefined;

  state: DetState = {
    selected: this.decorData.images[0].url,
    selectedObs: '',
    selectors: this.decorData.images.map((i, index) => {
      return index === 0 ? 'active' : '';
    }),
    selectedIndex: 0,
    gallerySelect: false,
    mode: 'image',
  };

  select(src: string, obs: string, index: number) {
    if (this.props.mode === 'autoplay') {
      this.props.changeMode('paused');
    }
    const tmp = this.state.selectors.map(() => {
      return '';
    });
    tmp[index] = 'active';
    this.setState({ selected: src, selectedObs: obs, selectors: tmp });
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.setState({ selectedIndex: index, gallerySelect: false, mode: 'image' });
    }
  }

  selectNext = () => {
    let next = this.state.selectedIndex + 1;
    if (next === this.decorData.images.length) {
      next = 0;
    }
    this.setState({ selectedIndex: next, selected: this.decorData.images[next].url });
  };

  selectPrev = () => {
    let next = this.state.selectedIndex - 1;
    if (next < 0) {
      next = this.decorData.images.length - 1;
    }
    this.setState({ selectedIndex: next, selected: this.decorData.images[next].url });
  };

  render() {
    const navBar = this.props.navBarEnabled && !window.orientation ? 'navBar-' : '';

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    const images = this.decorData.images.map((image, index) => {
      return (
        <div
          key={`img-${index}`}
          className={'selector-img-container ' + this.state.selectors[index]}
          onClick={() => this.select(image.url, '', index)}
        >
          <img className={'selector-img'} src={image.url} alt=""></img>
          <div className="border"></div>
        </div>
      );
    });

    const tableware = this.decorData.tableware.map((tw, index) => {
      return <li key={'tableware-' + index}>{tw}</li>;
    });

    const decoration = this.decorData.decoration.map((d, index) => {
      return <li key={'decoration-' + index}>{d}</li>;
    });

    if (mobile) {
      let main;
      switch (this.state.mode) {
        case 'image':
          main = (
            <div className="image">
              <LeftSVG onClick={() => this.selectPrev()} />
              <div className="container">
                <img
                  src={this.state.selected}
                  alt=""
                  onClick={() => this.setState({ mode: 'fullscreen' })}
                ></img>
              </div>
              <RightSVG onClick={() => this.selectNext()} />
            </div>
          );
          break;
        case 'info':
          main = (
            <div className="info">
              <div className="chars">
                <h2>Características</h2>
                <p>{this.decorData.characteristics}</p>
              </div>
              <div className="list">
                <h3>Tableware</h3>
                <ul>{tableware}</ul>
              </div>
              <div className="list">
                <h3>Decoração e mobiliário</h3>
                <ul style={{ marginBottom: 0 }}>{decoration}</ul>
              </div>
            </div>
          );
          break;
        case 'collection':
          main = <div className="collection">{images}</div>;
          break;
      }
      if (this.state.mode === 'fullscreen') {
        return (
          <div className="full">
            <LeftSVG onClick={() => this.selectPrev()} />
            <img src={this.state.selected} alt=""></img>
            <RightSVG onClick={() => this.selectNext()} />
            <CloseSVG className="full-close" onClick={() => this.setState({ mode: 'image' })} />
          </div>
        );
      }
      return (
        <div className="mobile-modal-det">
          <div className="header">
            <h3>Decor {this.decorData.name}</h3>
            <div className="svg-container">
              <CloseSVG
                onClick={() => {
                  this.props.close();
                }}
              />
            </div>
          </div>
          {main}
          <div className="footer">
            <ImageSVG
              className={this.state.mode === 'image' ? 'active' : ''}
              onClick={() => {
                this.setState({ mode: 'image' });
              }}
            />
            <InfoSVG
              className={this.state.mode === 'info' ? 'active' : ''}
              onClick={() => {
                this.setState({ mode: 'info' });
              }}
            />
            <GallerySVG
              className={this.state.mode === 'collection' ? 'active' : ''}
              onClick={() => {
                this.setState({ mode: 'collection' });
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={navBar + 'modal-det'}>
          <div className="head">
            <h1>Decor {this.decorData.name}</h1>
            <div className="svg-container">
              <CloseSVG
                onClick={() => {
                  this.props.close();
                }}
              />
            </div>
          </div>
          <div className="content">
            <div className="main-container">
              <img src={this.state.selected} alt=""></img>
              <div className="details">
                <div className="chars">
                  <h2>Características</h2>
                  <p>{this.decorData.characteristics}</p>
                </div>
                <div className="list">
                  <h3>Tableware</h3>
                  <ul>{tableware}</ul>
                </div>
                <div className="list">
                  <h3>Decoração e mobiliário</h3>
                  <ul style={{ marginBottom: 0 }}>{decoration}</ul>
                </div>
              </div>
            </div>
            <div className="selector">{images}</div>
          </div>
        </div>
      );
    }
  }
}
