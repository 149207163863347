import {
  MarzipanoData,
  MarzipanoScene,
  MarzipanoAutorotate,
  MarzipanoTransition,
} from '../types/marzipano';
import { transitionFunctions } from './marzipanoFunctions';
import { easingFunctions } from './easing';
import DeviceOrientationControlMethod from './DeviceOrientationControlMethod';
import { PAHotspot } from '../types/explorer';
import { GlobalVars } from './utils';

const Marzipano = require('marzipano');

var scenes: any[] = [];
var viewer: any;
var autorotateData: MarzipanoAutorotate | null;
var transitionData: MarzipanoTransition | undefined;
var anglesInDegrees: boolean;
var deviceOrientationControlMethod = new DeviceOrientationControlMethod();
var controls: any;
var allowGyroscope: boolean = false;

export const loadMarzipano = (appdata: MarzipanoData, id: string) => {
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  if (mobile) {
    allowGyroscope = appdata.settings.gyroscope?.enabled ? true : false;
  }

  var panoElement = document.getElementById(id);

  var opts: any = appdata.settings;
  opts.stageType = 'webgl';
  viewer = new Marzipano.Viewer(panoElement, opts);

  /*viewer.controls().enableMethodGroup('arrowKeys');
  viewer.controls().method('leftArrowKey').instance._velocity = -0.1;
  viewer.controls().method('rightArrowKey').instance._velocity = 0.1;
  viewer.controls().method('upArrowKey').instance._velocity = -0.1;
  viewer.controls().method('downArrowKey').instance._velocity = 0.1;*/
  scenes = [];
  scenes = appdata.scenes.map((scene) => {
    if (scene.flat?.enabled) {
      const createdScene = loadFlatImage(scene, appdata);
      return { scene: createdScene, id: scene.id, name: scene.name };
    } else {
      var geometry = new Marzipano.CubeGeometry(scene.levels);
      var useMobileFolder = mobile && appdata.settings.specialPanoramas?.mobile;
      var urlPrefix = useMobileFolder ? './tiles/mobile/' : './tiles/';
      if (scene.idLeft && scene.idRight) {
        const zoomFactor = scene.zoomFactor ? scene.zoomFactor : 1;
        const limiter = new Marzipano.RectilinearView.limit.traditional(
          scene.faceSize * zoomFactor,
          (100 * Math.PI) / 180,
          (120 * Math.PI) / 180,
        );
        const source = Marzipano.ImageUrlSource.fromString(
          urlPrefix + scene.id + '/{z}/{f}/{y}/{x}.jpg',
          {
            cubeMapPreviewUrl: urlPrefix + scene.id + '/preview.jpg',
          },
        );
        let initViewParams;
        if (appdata.settings.anglesInDegrees) {
          initViewParams = {
            yaw: (scene.initialViewParameters.yaw * Math.PI) / 180,
            pitch: (scene.initialViewParameters.pitch * Math.PI) / 180,
            fov: (scene.initialViewParameters.fov * Math.PI) / 180,
          };
          anglesInDegrees = true;
        } else {
          initViewParams = scene.initialViewParameters;
          anglesInDegrees = false;
        }
        const view = new Marzipano.RectilinearView(initViewParams, limiter);

        transitionData = appdata.settings.transition;
        const createdScene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
        });

        const leftSource = Marzipano.ImageUrlSource.fromString(
          urlPrefix + scene.idLeft + '/{z}/{f}/{y}/{x}.jpg',
          {
            cubeMapPreviewUrl: urlPrefix + scene.idLeft + '/preview.jpg',
          },
        );
        const rightSource = Marzipano.ImageUrlSource.fromString(
          urlPrefix + scene.idRight + '/{z}/{f}/{y}/{x}.jpg',
          {
            cubeMapPreviewUrl: urlPrefix + scene.idRight + '/preview.jpg',
          },
        );
        const stage = viewer.stage();

        var leftLayer = createdScene.createLayer({
          source: leftSource,
          geometry: geometry,
          textureStoreOpts: { source, stage },
          layerOpts: { effects: { rect: { relativeWidth: 0.5, relativeX: 0 } } },
        });
        var rightLayer = createdScene.createLayer({
          source: rightSource,
          geometry: geometry,
          textureStoreOpts: { source, stage },
          layerOpts: { effects: { rect: { relativeWidth: 0.5, relativeX: 0.5 } } },
        });

        var leftHC = new Marzipano.HotspotContainer(
          panoElement,
          stage,
          leftLayer.view(),
          viewer.renderLoop(),
          { rect: { relativeWidth: 0.5, relativeX: 0 } },
        );
        var rightHC = new Marzipano.HotspotContainer(
          panoElement,
          stage,
          rightLayer.view(),
          viewer.renderLoop(),
          { rect: { relativeWidth: 0.5, relativeX: 0.5 } },
        );

        return {
          scene: createdScene,
          id: scene.id,
          name: scene.name,
          leftHC: leftHC,
          rightHC: rightHC,
        };
      } else {
        var source = Marzipano.ImageUrlSource.fromString(
          urlPrefix + scene.id + '/{z}/{f}/{y}/{x}.jpg',
          {
            cubeMapPreviewUrl: urlPrefix + scene.id + '/preview.jpg',
          },
        );
        //TODO Zoom level should be a setting even if like a simple "zoom factor" like I did
        //https://github.com/google/marzipano/issues/38
        var zoomFactor = scene.zoomFactor ? scene.zoomFactor : 1;
        var limiter = new Marzipano.RectilinearView.limit.traditional(
          scene.faceSize * zoomFactor,
          (100 * Math.PI) / 180,
          (120 * Math.PI) / 180,
        );
        let initViewParams;
        if (appdata.settings.anglesInDegrees) {
          initViewParams = {
            yaw: (scene.initialViewParameters.yaw * Math.PI) / 180,
            pitch: (scene.initialViewParameters.pitch * Math.PI) / 180,
            fov: (scene.initialViewParameters.fov * Math.PI) / 180,
          };
          anglesInDegrees = true;
        } else {
          initViewParams = scene.initialViewParameters;
          anglesInDegrees = false;
        }
        var view = new Marzipano.RectilinearView(initViewParams, limiter);

        var createdScene = viewer.createScene({
          source: source,
          geometry: geometry,
          view: view,
        });

        transitionData = appdata.settings.transition;

        return { scene: createdScene, id: scene.id, name: scene.name };
      }
    }
  });
};

const loadFlatImage = (scene: MarzipanoScene, appdata: MarzipanoData) => {
  if (scene.flat?.enabled) {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    var geometry = new Marzipano.FlatGeometry(scene.levels);

    var useMobileFolder = mobile && appdata.settings.specialPanoramas?.mobile;
    var urlPrefix = useMobileFolder ? './tiles/mobile/' : './tiles/';
    var tileUrl = (z: number, x: number, y: number) => {
      if (scene.flat?.imageUrl) {
        return urlPrefix + scene.flat.imageUrl;
      } else {
        return (
          urlPrefix +
          scene.id +
          '/l' +
          z +
          '/' +
          y.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
          '/l' +
          z +
          '_' +
          y.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
          '_' +
          x.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) +
          '.jpg'
        );
      }
    };
    var source = new Marzipano.ImageUrlSource((tile: any) => {
      return { url: tileUrl(tile.z + 1, tile.x + 1, tile.y + 1) };
    });

    var limiter = Marzipano.util.compose(
      Marzipano.FlatView.limit.resolution(scene.flat.resolution),
      Marzipano.FlatView.limit.letterbox(),
    );
    /*var limiter = Marzipano.util.compose(
      Marzipano.FlatView.limit.resolution(scene.flat.resolution),
      Marzipano.FlatView.limit.visibleX(0, 1),
      Marzipano.FlatView.limit.visibleY(0, 1),
    );*/
    var initialViewParameters = {
      x: scene.initialViewParameters.yaw,
      y: scene.initialViewParameters.pitch,
      zoom: scene.initialViewParameters.fov,
      mediaAspectRatio: scene.flat?.mediaAspectRatio,
    };
    var view = new Marzipano.FlatView(initialViewParameters, limiter);

    var createdScene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true,
    });

    return createdScene;
  }
};

export const loadHotspots = (appdata: MarzipanoData, values: any, paHostpots: PAHotspot[]) => {
  scenes = scenes.map((scene, index) => {
    if (scene.scene.view().x) {
      loadFlatHotspots(scene, index, appdata);
      return scene;
    } else {
      if (!appdata.scenes[index].idLeft || !appdata.scenes[index].idLeft) {
        if (values && values.focus) {
          if (appdata.scenes[index].id === values.space) {
            const perspective = { radius: 500 };
            let position;
            if (appdata.settings.anglesInDegrees) {
              position = {
                yaw: (values.yaw * Math.PI) / 180,
                pitch: (values.pitch * Math.PI) / 180,
              };
            } else {
              position = { yaw: values.yaw, pitch: values.pitch };
            }
            scene.scene
              .hotspotContainer()
              .createHotspot(
                document.getElementsByClassName('permalink-focus')[0],
                position,
                perspective,
              );
          }
        }
        appdata.scenes[index].articleHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-article ' + scene.id)[index],
              position,
            );
        });

        appdata.scenes[index].infoHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-info ' + scene.id)[index],
              position,
            );
        });

        appdata.scenes[index].linkHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }

          const persp = hotspot.perspective ? { perspective: hotspot.perspective } : null;

          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-link ' + scene.id)[index],
              position,
              persp,
            );
        });

        appdata.scenes[index].imgHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-img ' + scene.id)[index],
              position,
            );
        });

        appdata.scenes[index].labelHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-label ' + scene.id)[index],
              position,
              { perspective: hotspot.perspective },
            );
        });

        appdata.scenes[index].mediaHotspots?.map((hotspot, index) => {
          const perspective = hotspot.perspective;
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-media ' + scene.id)[index],
              position,
              { perspective: perspective },
            );
        });

        appdata.scenes[index].modalHotspots?.map((hotspot, index) => {
          let position;
          if (appdata.settings.anglesInDegrees) {
            position = {
              yaw: (hotspot.yaw * Math.PI) / 180,
              pitch: (hotspot.pitch * Math.PI) / 180,
            };
          } else {
            position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
          }
          scene.scene
            .hotspotContainer()
            .createHotspot(
              document.getElementsByClassName('hotspot-modal ' + scene.id)[index],
              position,
            );
        });

        if (appdata.nadir && appdata.settings.showNadir !== false) {
          scene.scene.hotspotContainer().createHotspot(
            document.getElementById('nadirHS ' + scene.id),
            { yaw: 0, pitch: Math.PI / 2 },
            {
              perspective: {
                extraRotations: 'rotateZ(0deg) rotateY(0deg)',
                radius: 1250,
              },
            },
          );
        }

        if (paHostpots.length > 0) {
          paHostpots.forEach((pa, index) => {
            if (scene.id === pa.scene) {
              scene.scene
                .hotspotContainer()
                .createHotspot(document.getElementById('pa-' + pa.scene + '-' + index), {
                  yaw: pa.pos.yaw,
                  pitch: pa.pos.pitch,
                });
            }
          });
        }
      } else {
        loadCompareHotspots(scene, index, appdata);
      }
      return scene;
    }
  });

  if (appdata.settings.autorotateEnabled && appdata.settings.autorotate) {
    autorotateData = {
      yawSpeed: appdata.settings.autorotate.yawSpeed,
      targetPitch: appdata.settings.autorotate.targetPitch,
      targetFov: appdata.settings.autorotate.targetFov,
      idleTime: appdata.settings.autorotate.idleTime,
    };
    startAutoRotate();
  } else {
    autorotateData = null;
  }
};

const loadFlatHotspots = (scene: any, index: number, appdata: MarzipanoData) => {
  appdata.scenes[index].infoHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }
    scene.scene
      .hotspotContainer()
      .createHotspot(document.getElementsByClassName('hotspot-info ' + scene.id)[index], position);
  });

  appdata.scenes[index].linkHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }

    const persp = hotspot.perspective
      ? { perspective: { extraTransforms: hotspot.perspective.extraTransforms } }
      : null;

    scene.scene
      .hotspotContainer()
      .createHotspot(
        document.getElementsByClassName('hotspot-link ' + scene.id)[index],
        position,
        persp,
      );
  });

  appdata.scenes[index].imgHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }
    scene.scene
      .hotspotContainer()
      .createHotspot(document.getElementsByClassName('hotspot-img ' + scene.id)[index], position);
  });

  appdata.scenes[index].labelHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }
    scene.scene
      .hotspotContainer()
      .createHotspot(
        document.getElementsByClassName('hotspot-label ' + scene.id)[index],
        position,
        { perspective: hotspot.perspective },
      );
  });

  appdata.scenes[index].mediaHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }

    const persp = { extraTransforms: hotspot.perspective.extraTransforms };

    scene.scene
      .hotspotContainer()
      .createHotspot(
        document.getElementsByClassName('hotspot-media ' + scene.id)[index],
        position,
        { perspective: persp },
      );
  });

  appdata.scenes[index].modalHotspots?.map((hotspot, index) => {
    let position;
    if (appdata.settings.anglesInDegrees) {
      position = {
        x: (hotspot.yaw * Math.PI) / 180,
        y: (hotspot.pitch * Math.PI) / 180,
      };
    } else {
      position = { x: hotspot.yaw, y: hotspot.pitch };
    }
    scene.scene
      .hotspotContainer()
      .createHotspot(document.getElementsByClassName('hotspot-modal ' + scene.id)[index], position);
  });
};

const loadCompareHotspots = (scene: any, idx: number, appdata: MarzipanoData) => {
  if (scene.leftHC && scene.rightHC) {
    appdata.scenes[idx].articleHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = {
          yaw: (hotspot.yaw * Math.PI) / 180,
          pitch: (hotspot.pitch * Math.PI) / 180,
        };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-article left ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].leftHotspots?.infoHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-info left ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].leftHotspots?.linkHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-link left ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].leftHotspots?.imgHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.lefHC.createHotspot(
        document.getElementsByClassName('hotspot-img left ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].leftHotspots?.labelHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-label left ' + scene.id)[index],
        position,
        { perspective: hotspot.perspective },
      );
    });

    appdata.scenes[idx].leftHotspots?.mediaHotspots?.map((hotspot, index) => {
      const perspective = hotspot.perspective;
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-media left ' + scene.id)[index],
        position,
        { perspective: perspective },
      );
    });

    appdata.scenes[idx].leftHotspots?.modalHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.leftHC.createHotspot(
        document.getElementsByClassName('hotspot-modal left ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].articleHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = {
          yaw: (hotspot.yaw * Math.PI) / 180,
          pitch: (hotspot.pitch * Math.PI) / 180,
        };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-article right ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].rightHotspots?.infoHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-info right ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].rightHotspots?.linkHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-link right ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].rightHotspots?.imgHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.lefHC.createHotspot(
        document.getElementsByClassName('hotspot-img right ' + scene.id)[index],
        position,
      );
    });

    appdata.scenes[idx].rightHotspots?.labelHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-label right ' + scene.id)[index],
        position,
        { perspective: hotspot.perspective },
      );
    });

    appdata.scenes[idx].rightHotspots?.mediaHotspots?.map((hotspot, index) => {
      const perspective = hotspot.perspective;
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-media right ' + scene.id)[index],
        position,
        { perspective: perspective },
      );
    });

    appdata.scenes[idx].rightHotspots?.modalHotspots?.map((hotspot, index) => {
      let position;
      if (appdata.settings.anglesInDegrees) {
        position = { yaw: (hotspot.yaw * Math.PI) / 180, pitch: (hotspot.pitch * Math.PI) / 180 };
      } else {
        position = { yaw: hotspot.yaw, pitch: hotspot.pitch };
      }
      scene.rightHC.createHotspot(
        document.getElementsByClassName('hotspot-modal right ' + scene.id)[index],
        position,
      );
    });

    if (appdata.nadir && appdata.settings.showNadir) {
      scene.leftHC.createHotspot(
        document.getElementById('nadirHS ' + scene.id + ' left'),
        { yaw: 0, pitch: Math.PI / 2 },
        {
          perspective: {
            extraRotations: 'rotateZ(0deg) rotateY(0deg)',
            radius: 1250,
          },
        },
      );
      scene.rightHC.createHotspot(
        document.getElementById('nadirHS ' + scene.id + ' right'),
        { yaw: 0, pitch: Math.PI / 2 },
        {
          perspective: {
            extraRotations: 'rotateZ(0deg) rotateY(0deg)',
            radius: 1250,
          },
        },
      );
    }
  }
};

export const toggleLeftAndRightHotspots = (sceneId: string) => {
  scenes.forEach((scene) => {
    if (scene.leftHC && scene.rightHC) {
      if (scene.id !== sceneId) {
        const hotspotList = scene.leftHC.listHotspots();
        for (let i = 0; i < hotspotList.length; i++) {
          hotspotList[i].hide();
          //scene.leftHC.destroyHotspot(hotspotList[i]);
        }
        const hotspotRList = scene.rightHC.listHotspots();
        for (let j = 0; j < hotspotRList.length; j++) {
          hotspotRList[j].hide();
          //scene.rightHC.destroyHotspot(hotspotRList[j]);
        }
      } else {
        const hotspotList = scene.leftHC.listHotspots();
        for (let i = 0; i < hotspotList.length; i++) {
          hotspotList[i].show();
          //scene.leftHC.destroyHotspot(hotspotList[i]);
        }
        const hotspotRList = scene.rightHC.listHotspots();
        for (let j = 0; j < hotspotRList.length; j++) {
          hotspotRList[j].show();
          //scene.rightHC.destroyHotspot(hotspotRList[j]);
        }
      }
    }
  });
};

export const destroyAllHostpots = () => {
  scenes.forEach((scene) => {
    if (scene.leftHC && scene.rightHC) {
      const hotspotList = scene.leftHC.listHotspots();
      const hotspotRList = scene.rightHC.listHotspots();
      for (let i = 0; i < hotspotList.length; i++) {
        //hotspotList[i].hide();
        scene.leftHC.destroyHotspot(hotspotList[i]);
      }
      for (let i = 0; i < hotspotRList.length; i++) {
        //hotspotList[i].hide();
        scene.rightHC.destroyHotspot(hotspotRList[i]);
      }
    }
    const hotspotList = scene.scene.hotspotContainer().listHotspots();
    for (var i = 0; i < hotspotList.length; i++) {
      //hotspotList[i].hide();
      scene.scene.hotspotContainer().destroyHotspot(hotspotList[i]);
    }
  });
};

export const showAllHostpots = (sceneId: string) => {
  scenes.forEach((scene) => {
    if (scene.scene.id === sceneId) {
      const hotspotList = scene.scene.hotspotContainer().listHotspots();
      for (var i = 0; i < hotspotList.length; i++) {
        hotspotList[i].show();
      }
    }
  });
};

export const switchScene = (scene: MarzipanoScene | null) => {
  if (scene) {
    let fun, ease, duration;
    if (transitionData) {
      if (transitionFunctions.hasOwnProperty(transitionData.animation)) {
        fun = transitionFunctions[transitionData.animation];
      }
      if (easingFunctions.hasOwnProperty(transitionData.ease)) {
        ease = easingFunctions[transitionData.ease];
      }
      duration = transitionData.duration;
    }
    const viewerScene = findSceneById(scene.id);
    if (fun && ease && duration) {
      viewerScene.switchTo({
        transitionDuration: duration,
        transitionUpdate: fun(ease),
      });
    } else {
      viewerScene.switchTo({
        transitionDuration: 1000,
      });
    }
  }
};

export const findSceneById = (id: string) => {
  for (var i = 0; i < scenes.length; i++) {
    if (scenes[i].id === id) {
      return scenes[i].scene;
    }
  }
  return null;
};

export const getNameById = (id: string) => {
  for (var i = 0; i < scenes.length; i++) {
    if (scenes[i].id === id) {
      return scenes[i].name;
    }
  }
  return null;
};

export const startAutoRotate = () => {
  if (autorotateData) {
    var autorotate = Marzipano.autorotate({
      yawSpeed: autorotateData.yawSpeed,
      targetPitch: autorotateData.targetPitch,
      targetFov: autorotateData.targetFov,
    });

    viewer.setIdleMovement(autorotateData.idleTime, autorotate);
    viewer.breakIdleMovement();
  } else {
    stopAutoRotate();
  }
  if (controls && !GlobalVars.socket && GlobalVars.controller === undefined) {
    enable();
  }
};

export const stopAutoRotate = () => {
  viewer.stopMovement();
  viewer.setIdleMovement(Infinity);
  if (controls) {
    disable();
  }
};

export const changeCamera = (
  yaw: number,
  pitch: number,
  fov: number | undefined,
  duration: number | null | undefined,
  doneCallback: CallableFunction,
  degrees?: boolean,
) => {
  const scene = viewer.scene();
  if (scene) {
    let y: number, p: number, f: number;
    if (anglesInDegrees && degrees !== false) {
      y = (yaw * Math.PI) / 180;
      p = (pitch * Math.PI) / 180;
      f = fov ? (fov * Math.PI) / 180 : scene.view().fov();
    } else {
      if (scene.view().x) {
        y = yaw;
        p = pitch;
        f = fov ? fov : scene.view().zoom();
      } else {
        y = yaw;
        p = pitch;
        f = fov ? fov : scene.view().fov();
      }
    }
    let dest;
    if (scene.view().x) {
      dest = { x: y, y: p, zoom: f };
    } else {
      dest = { yaw: y, pitch: p, fov: f };
    }
    const options = {
      transitionDuration: duration !== undefined || duration !== null ? duration : 1000,
    };
    const sceneYaw = scene.view().x ? scene.view().x() : scene.view().yaw();
    const scenePitch = scene.view().y ? scene.view().y() : scene.view().pitch();
    const sceneFov = scene.view().zoom ? scene.view().zoom() : scene.view().fov();
    if (
      Math.abs(y - sceneYaw) > 0.01 ||
      Math.abs(p - scenePitch) > 0.01 ||
      Math.abs(f - sceneFov) > 0.01
    ) {
      viewer.scene().lookTo(dest, options, () => {
        doneCallback();
      });
    } else {
      doneCallback();
    }
  }
};

export const zoom = (fov: number) => {
  viewer.view().setFov(fov);
};

export const removeAllHotspots = () => {
  scenes.forEach((s) => {
    if (s.scene.hotspotContainer()) {
      s.scene
        .hotspotContainer()
        .listHotspots()
        .map((h: any) => {
          s.scene.hotspotContainer().destroyHotspot(h);
          return true;
        });
    }
  });
  viewer.destroyAllScenes();
};

export const changePerspective = (extraTransforms: string, sceneId: string, elementId: string) => {
  scenes = scenes.map((s) => {
    if (s.id === sceneId) {
      s.scene
        .hotspotContainer()
        .listHotspots()
        .map((hotspot: any) => {
          if (hotspot._domElement.id === elementId) {
            const perspective = {
              radius: hotspot._perspective.radius,
              extraTransforms: extraTransforms,
            };
            hotspot.setPerspective(perspective);
          }
          return true;
        });
    }
    return s;
  });
};

/*export const gyroscope = () => {
  if (window.orientation) {
    window.addEventListener('deviceorientation', (e: DeviceOrientationEvent) => {
      if (e.alpha !== null && e.gamma !== null) {
        viewer.scene().view().setPitch(0);
        viewer
          .scene()
          .view()
          .setYaw((-e.alpha * Math.PI) / 180);
      }
    });
  }
};*/

export const gyroscope = () => {
  if (allowGyroscope) {
    controls = viewer.controls();
    controls.registerMethod('deviceOrientation', deviceOrientationControlMethod);

    if (DeviceOrientationEvent) {
      if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
        requestPermissionForIOS();
      } else {
        enableDeviceOrientation();
      }
    }
  }
};

const requestPermissionForIOS = () => {
  (window.DeviceOrientationEvent as any)
    .requestPermission()
    .then((response: any) => {
      if (response === 'granted') {
        enableDeviceOrientation();
      }
    })
    .catch((e: any) => {
      console.error(e);
    });
};

const enableDeviceOrientation = () => {
  deviceOrientationControlMethod.getPitch((err: any, pitch: number) => {
    if (!err) {
      const view = viewer.scene().view();
      view.setPitch(pitch);
    }
  });
  if (controls) controls.enableMethod('deviceOrientation');
};

export const enable = () => {
  if (allowGyroscope) {
    if (DeviceOrientationEvent) {
      if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
        requestPermissionForIOS();
      } else {
        enableDeviceOrientation();
      }
    }
  }
};

export const disable = () => {
  if (controls) controls.disableMethod('deviceOrientation');
};

export const position = () => {
  const yaw = viewer.scene().view().x ? viewer.scene().view().x() : viewer.scene().view().yaw();
  const pitch = viewer.scene().view().y ? viewer.scene().view().y() : viewer.scene().view().pitch();
  const fov = viewer.scene().view().zoom
    ? viewer.scene().view().zoom()
    : viewer.scene().view().fov();
  return { yaw: yaw, pitch: pitch, fov: fov };
};

export const mousePositionToMarzipanoCoords = (x: Number, y: Number) => {
  const coords = viewer.scene().view().screenToCoordinates({ x: x, y: y });
  return coords.x
    ? `"yaw": ${coords.x.toFixed(2)}, "pitch": ${coords.y.toFixed(2)}`
    : `"yaw": ${coords.yaw.toFixed(2)}, "pitch": ${coords.pitch.toFixed(2)}`;
};

export const mousePositionToCoords = (x: Number, y: Number) => {
  const coords = viewer.scene().view().screenToCoordinates({ x: x, y: y });
  return coords;
};

export const isFlat = () => {
  return viewer.scene()?.view().x ? true : false;
};

export const addEventEmissionOnDrag = () => {
  if (viewer.view()) {
    viewer.view().removeEventListener('change', changeEmission);
    viewer.view().addEventListener('change', changeEmission);
  }
};

export const removeEventEmissionOnDrag = () => {
  if (viewer.view()) {
    viewer.view().removeEventListener('change', changeEmission);
  }
};

const changeEmission = () => {
  const pos = position();
  if (GlobalVars.controller && GlobalVars.socket) {
    GlobalVars.socket?.emit('position', pos);
  }
};

var pulseHotspot: any;

export const addPulse = (pos: any) => {
  pulseHotspot = viewer
    .scene()
    .hotspotContainer()
    .createHotspot(document.getElementsByClassName('pulse')[0], pos);
};

export const removePulse = () => {
  if (pulseHotspot) {
    viewer.scene().hotspotContainer().destroyHotspot(pulseHotspot);
    pulseHotspot = undefined;
  }
};
