import React from 'react';
import { loadMarzipano, mousePositionToCoords, switchScene } from '../libs/marzipano';
import { GlobalVars } from '../libs/utils';
//import Hotspots from './Hotspots';
import { MarzipanoViewerProps } from '../types/marzipanoViewer';

export default class Marzipano extends React.Component<MarzipanoViewerProps> {
  mouseDown: boolean = false;
  componentDidMount() {
    const selScene = Array.isArray(this.props.selectedScene)
      ? this.props.selectedScene[this.props.timelapseIndex]
      : this.props.selectedScene;
    loadMarzipano(this.props.appdata, 'pano');
    const scene = this.props.appdata.scenes.find((scene) => scene.id === selScene);
    if (scene != null) {
      switchScene(scene);
    }
    this.props.load();
  }

  componentDidUpdate(prevProps: MarzipanoViewerProps) {
    let code;
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      code = urlParams.get('loc');
    }
    if (!this.props.loaded && code) {
      loadMarzipano(this.props.appdata, 'pano');
      const selScene = Array.isArray(this.props.selectedScene)
        ? this.props.selectedScene[this.props.timelapseIndex]
        : this.props.selectedScene;
      const scene = this.props.appdata.scenes.find((scene) => scene.id === selScene);
      if (scene != null) {
        switchScene(scene);
      }
      this.props.load();
    }
    if (prevProps.appdata !== this.props.appdata) {
      loadMarzipano(this.props.appdata, 'pano');
      const selScene = Array.isArray(this.props.selectedScene)
        ? this.props.selectedScene[this.props.timelapseIndex]
        : this.props.selectedScene;
      const scene = this.props.appdata.scenes.find((scene) => scene.id === selScene);
      if (scene != null) {
        switchScene(scene);
      }
      this.props.load();
    }

    if (
      prevProps.selectedScene !== this.props.selectedScene &&
      (this.props.type === '360' || this.props.type === '360_compare')
    ) {
      const selScene = Array.isArray(this.props.selectedScene)
        ? this.props.selectedScene[this.props.timelapseIndex]
        : this.props.selectedScene;
      const newScene = this.props.appdata.scenes.find((scene) => scene.id === selScene);
      if (newScene) {
        switchScene(newScene);
      }
    }

    if (prevProps.timelapseIndex !== this.props.timelapseIndex) {
      const selScene = Array.isArray(this.props.selectedScene)
        ? this.props.selectedScene[this.props.timelapseIndex]
        : this.props.selectedScene;
      const scene = this.props.appdata.scenes.find((scene) => scene.id === selScene);
      if (scene != null) {
        switchScene(scene);
      }
    }
  }

  render() {
    const display =
      this.props.type === '360' || this.props.type === '360_compare' ? 'block' : 'none';
    return (
      <div
        className="marzipano"
        style={{ display: display }}
        onMouseDown={() => {
          if (!this.props.active) this.props.activate();
          if (this.props.mode === 'autoplay') this.props.changeMode('paused');
          this.mouseDown = true;
        }}
        onMouseUp={() => (this.mouseDown = false)}
        onTouchStart={() => {
          if (!this.props.active) this.props.activate();
          if (this.props.mode === 'autoplay') this.props.changeMode('paused');
        }}
        onWheel={() => {
          if (!this.props.active) this.props.activate();
          if (this.props.mode === 'autoplay') this.props.changeMode('paused');
        }}
        onDoubleClick={(e: any) => {
          if (this.props.live && GlobalVars.controller && GlobalVars.socket) {
            const pos = mousePositionToCoords(e.clientX, e.clientY);
            GlobalVars.socket.emit('pointHighlight', { x: pos.yaw, y: pos.pitch });
          }
        }}
      >
        <div
          style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw' }}
          id="pano"
        ></div>
      </div>
    );
  }
}
