import React, { useState } from 'react';
import './input-text.scss';
import { ReactComponent as TargetSVG } from '../icon/target.svg';
import { ReactComponent as SendSVG } from '../icon/send.svg';
import { encodeHash, getCurrentUrl, GlobalVars } from '../libs/utils';
import { changeCamera, mousePositionToMarzipanoCoords, position } from '../libs/marzipano';

export interface ITextProps {
  startMessaging: CallableFunction;
  getAllConfigs: CallableFunction;
  language: string;
}

var cursorX: any = 0;
var cursorY: any = 0;

function InputText(props: ITextProps) {
  const [text, setText] = useState('');
  const [pointAndAsk, setPointAndAsk] = useState(false);

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    if (GlobalVars.chat && GlobalVars.socket) {
      GlobalVars.socket.emit('newMessage', text);
    } else {
      props.startMessaging(text);
    }
    setText('');
  };

  const handlePointAndAsk = () => {
    const pano = document.getElementById('pano');
    if (!pointAndAsk) {
      onmousemove = (e) => {
        cursorX = e.clientX;
        cursorY = e.clientY;
      };
      if (pano) {
        pano.ondblclick = () => {
          handleDoubleClick();
        };
      }
    } else {
      onmousemove = () => {};
      if (pano) {
        pano.ondblclick = () => {};
      }
    }

    setPointAndAsk(!pointAndAsk);
  };

  const handleDoubleClick = () => {
    const mousePos = JSON.parse(
      '{' + mousePositionToMarzipanoCoords(cursorX.valueOf(), cursorY.valueOf()) + '}',
    );
    changeCamera(mousePos.yaw, mousePos.pitch, undefined, 1000, () => {
      const pos = position();
      const configs = props.getAllConfigs();
      const space = Array.isArray(configs.space) ? configs.space[configs.timelapse] : configs.space;
      const code = encodeHash(
        configs.mapping.split('.')[0],
        configs.sector,
        space,
        pos.yaw,
        pos.pitch,
        pos.fov,
        String(configs.timelapse),
        'test',
        'true',
      );
      let preMsg = 'Aceda ao seguinta link para verificar o local indicado: \n\n';
      switch (props.language) {
        case 'English':
          preMsg = 'Access the following link to check the pointed location: \n\n';
          break;

        default:
          preMsg = 'Aceda ao seguinta link para verificar o local indicado: \n\n';
          break;
      }
      const message = preMsg + getCurrentUrl() + '?loc=' + code;
      props.startMessaging(message);
      setPointAndAsk(false);
    });
  };

  let pointAskMsg = 'Faça duplo clique para indicar um local';
  switch (props.language) {
    case 'English':
      pointAskMsg = 'Double click to point any space';
      break;

    default:
      pointAskMsg = 'Faça duplo clique para indicar um local';
      break;
  }

  return (
    <div className="input-text-container">
      {pointAndAsk ? <div className="title-indication">{pointAskMsg}</div> : null}
      {text ? (
        <div className="input-text-interaction-area">
          <form onSubmit={handleSubmit}>
            <textarea
              value={text}
              className="typo-body-chat changed"
              placeholder="Aa"
              onChange={(e: any) => setText(e.target.value)}
            />
            <button type="submit" value="Submit">
              <SendSVG />
            </button>
          </form>
        </div>
      ) : (
        <div className="input-text-interaction-area">
          <form onSubmit={handleSubmit}>
            <textarea
              value={text}
              className="typo-body-chat"
              placeholder="Aa"
              onChange={(e: any) => setText(e.target.value)}
            />
            <TargetSVG
              className={pointAndAsk ? 'pa-active' : ''}
              onClick={() => handlePointAndAsk()}
            />
          </form>
        </div>
      )}
    </div>
  );
}

export default InputText;
