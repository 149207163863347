import React, { useState, FunctionComponent } from 'react';
import './remoteBar.css';
import { ReactComponent as MicOffSVG } from '../icon/mic_off.svg';
import { ReactComponent as VideoOffSVG } from '../icon/videocam_off.svg';
import { ReactComponent as MoreSVG } from '../icon/more_vertical.svg';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
import { ReactComponent as SyncSVG } from '../icon/sync-screen.svg';
import { GlobalVars } from '../libs/utils';

interface RemoteBarProps {
  mute: boolean;
  camera: boolean;
  name: string;
  changeController: CallableFunction;
  live: boolean;
  removeControll: boolean;
}

const RemoteBar: FunctionComponent<RemoteBarProps> = ({
  mute,
  camera,
  name,
  changeController,
  live,
  removeControll,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [changedController, setChangedController] = useState<boolean>(false);

  const changedControllerPrompt = (
    <div
      className="prompt-background"
      onClick={() => {
        setChangedController(false);
      }}
    >
      <div className="changed-container">
        <SyncSVG />
        <p>O controlo da visita guiada foi alterado com sucesso.</p>
        <p>
          Para obter novamente o controlo da visita, pressione o ícone <strong>Sincronizar</strong>
          <SyncSVG />
        </p>
        <div className="buttons">
          <div
            className="cancel"
            onClick={() => {
              setChangedController(false);
            }}
          >
            COMPREENDI
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <div key="remote-bar" className="remote-bar">
          <div className="media-info">
            {mute && <MicOffSVG />}
            {camera && <VideoOffSVG />}
          </div>
          <MoreSVG
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
        </div>
        <div className={showMenu ? 'remote-tools active' : 'remote-tools'}>
          <div className="remote-tool">
            <InfoSVG />
            {name}
          </div>
          {GlobalVars.admin && !removeControll && live && (
            <div
              className="remote-tool"
              onClick={() => {
                changeController();
                setChangedController(true);
                setShowMenu(false);
              }}
            >
              <SyncSVG />
              Dar controlo
            </div>
          )}
        </div>
      </div>
      {changedController && changedControllerPrompt}
    </>
  );
};

export default RemoteBar;
