import React, { useState, useEffect } from 'react';
import Header from './Header';
import Greeting from './Greeting';
import Messages from './Messages';
import InputText from './InputText';
import './index.scss';
import { GlobalVars } from '../libs/utils';
import { io } from 'socket.io-client';
import { LandingPageConfiguration } from '../types/landingPage';

export interface ChatBoxProps {
  closeCallback: CallableFunction;
  getAllConfigs: CallableFunction;
}

function Index(props: ChatBoxProps) {
  //const [inputText, setInputText] = useState('');
  const landingPageConfig: LandingPageConfiguration = require('../JSON/landing_page.json');
  const [messages, setMessages] = useState<{ sid: number; sender: string; text: string }[]>();

  let helloMsg = 'Olá';
  let helpMsg = 'Em que podemos ajudar?';
  switch (props.getAllConfigs().language) {
    case 'English':
      helloMsg = 'Hello';
      helpMsg = 'How can we help?';
      break;

    default:
      helloMsg = 'Olá';
      helpMsg = 'Em que podemos ajudar?';
      break;
  }

  const startMessaging = (newMessage: string) => {
    if (GlobalVars.socket) {
      GlobalVars.socket.emit('createConversation');
      GlobalVars.socket.on('conversationCreated', () => {
        GlobalVars.chat = true;
        if (GlobalVars.socket) {
          let urlNumber;
          if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            urlNumber = '+351' + urlParams.get('number');
          }
          const addressNumber = urlNumber ? urlNumber : landingPageConfig.chat?.number;
          GlobalVars.socket.emit('addSMSParticipant', addressNumber);
          GlobalVars.socket.emit('addChatParticipant');
          GlobalVars.socket.on(
            'msgAdded',
            (newMessages: { sid: number; sender: string; text: string }[]) => {
              newMessages.unshift(
                { sid: 1, sender: 'support', text: helloMsg },
                { sid: 2, sender: 'support', text: helpMsg },
              );
              setMessages(newMessages);
            },
          );
          GlobalVars.socket.on('participantsAdded', () => {
            if (GlobalVars.socket) GlobalVars.socket.emit('newMessage', newMessage);
          });
        }
      });
    } else {
      GlobalVars.socket = io('https://twilio.alive.vision');
      //GlobalVars.socket = io('http://localhost:3000');
      GlobalVars.socket.on('connect', () => {
        if (GlobalVars.socket) {
          GlobalVars.socket.emit('createConversation');
          GlobalVars.socket.on('conversationCreated', () => {
            GlobalVars.chat = true;
            if (GlobalVars.socket) {
              let urlNumber;
              if (window.location.search) {
                const urlParams = new URLSearchParams(window.location.search);
                urlNumber = '+351' + urlParams.get('number');
              }
              const addressNumber = urlNumber ? urlNumber : landingPageConfig.chat?.number;
              GlobalVars.socket.emit('addSMSParticipant', addressNumber);
              GlobalVars.socket.emit('addChatParticipant');
              GlobalVars.socket.on(
                'msgAdded',
                (newMessages: { sid: number; sender: string; text: string }[]) => {
                  newMessages.unshift(
                    { sid: 1, sender: 'support', text: helloMsg },
                    { sid: 2, sender: 'support', text: helpMsg },
                  );
                  setMessages(newMessages);
                },
              );
              GlobalVars.socket.on('participantsAdded', () => {
                if (GlobalVars.socket) GlobalVars.socket.emit('newMessage', newMessage);
              });
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (GlobalVars.socket && GlobalVars.chat) {
      GlobalVars.socket.emit('getAllMessages');
      GlobalVars.socket.on(
        'allMessagesSent',
        (newMessages: { sid: number; sender: string; text: string }[]) => {
          newMessages.unshift(
            { sid: 1, sender: 'support', text: helloMsg },
            { sid: 2, sender: 'support', text: helpMsg },
          );
          setMessages(newMessages);
        },
      );
      GlobalVars.socket.on(
        'msgAdded',
        (newMessages: { sid: number; sender: string; text: string }[]) => {
          newMessages.unshift(
            { sid: 1, sender: 'support', text: helloMsg },
            { sid: 2, sender: 'support', text: helpMsg },
          );
          setMessages(newMessages);
        },
      );
    } else {
      setMessages([
        { sid: 1, sender: 'support', text: helloMsg },
        { sid: 2, sender: 'support', text: helpMsg },
      ]);
    }
  }, [helloMsg, helpMsg]);

  return (
    <div className="chat-container">
      <Header closeCallback={props.closeCallback} />
      <Greeting language={props.getAllConfigs().language} />
      <Messages messages={messages} />
      <InputText
        startMessaging={startMessaging}
        getAllConfigs={props.getAllConfigs}
        language={props.getAllConfigs().language}
      />
    </div>
  );
}

export default Index;
