import React from 'react';
import './modalExpo.template.css';
import { ModalHotspotProps } from '../../types/hotspot';
import { ReactComponent as CloseSVG } from '../../icon/close.svg';
import { MarzipanoExpoModalHotspotImage } from '../../types/marzipano';
import { ReactComponent as LeftSVG } from '../../icon/left.svg';
import { ReactComponent as RightSVG } from '../../icon/right.svg';

interface ModalExpoProps extends ModalHotspotProps {
  close: CallableFunction;
}

interface ModalExpoState {
  selectedIdx: number;
}

export default class Modal1 extends React.Component<ModalExpoProps> {
  state: ModalExpoState = {
    selectedIdx: 0,
  };

  visitLink = () => {
    window.open(
      (this.props.info.images[this.state.selectedIdx] as MarzipanoExpoModalHotspotImage).linkToExpo,
    );
  };

  prevImage = () => {
    const next =
      this.state.selectedIdx === 0 ? this.props.info.images.length - 1 : this.state.selectedIdx - 1;
    this.setState({ selectedIdx: next });
  };

  nextImage = () => {
    const next =
      this.state.selectedIdx === this.props.info.images.length - 1 ? 0 : this.state.selectedIdx + 1;
    this.setState({ selectedIdx: next });
  };

  render() {
    const navBar = this.props.navBarEnabled && !window.orientation ? 'navBar-' : '';

    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let gallery = null;
    if (this.props.info.images.length > 1) {
      let images = this.props.info.images.map((img, index) => {
        const className = index === this.state.selectedIdx ? 'selected' : '';
        return (
          <img
            key={`expo-img-${index}`}
            className={className}
            src={img.url}
            alt=""
            onClick={() => {
              this.setState({ selectedIdx: index });
            }}
          ></img>
        );
      });

      gallery = <div className="gallery">{images}</div>;
    }

    const sel: MarzipanoExpoModalHotspotImage = this.props.info.images[
      this.state.selectedIdx
    ] as MarzipanoExpoModalHotspotImage;

    if (mobile) {
      return (
        <div className="mobile-modal-expo">
          <h1>
            {this.props.info.title}
            <CloseSVG onClick={() => this.props.close()} />
          </h1>
          <div className="mobile-content-expo">
            {this.props.info.images.length > 1 ? (
              <LeftSVG
                onClick={() => {
                  this.prevImage();
                }}
              />
            ) : null}
            <div className="mobile-modal-img">
              <img src={sel.url} alt=""></img>
            </div>
            <div className="mobile-text">
              <h1>{sel.author}</h1>
              <p>
                <b>{sel.title}</b>
              </p>
              <p>{sel.obs}</p>
              <p>{sel.dimension}</p>
              <h2>
                PVP: <span>{typeof sel.pvp === 'number' ? `${sel.pvp.toFixed(2)}€` : sel.pvp}</span>
              </h2>
              <div
                onClick={() => {
                  this.visitLink();
                }}
              >
                Ir para a loja
              </div>
            </div>
            {this.props.info.images.length > 1 ? (
              <RightSVG
                onClick={() => {
                  this.nextImage();
                }}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className={navBar + 'modal-expo'}>
          <h1>
            {this.props.info.title}
            <CloseSVG onClick={() => this.props.close()} />
          </h1>
          <div className="modal-wrapper">
            <img src={sel.url} alt=""></img>
            <div className="information">
              <h1>{sel.author}</h1>
              <p>
                <b>{sel.title}</b>
              </p>
              <p>{sel.obs}</p>
              <p>{sel.dimension}</p>
              <h2>
                PVP: <span>{typeof sel.pvp === 'number' ? `${sel.pvp.toFixed(2)}€` : sel.pvp}</span>
              </h2>
              <div
                onClick={() => {
                  this.visitLink();
                }}
              >
                Ir para a loja
              </div>
            </div>
          </div>
          {gallery}
          <div className="footer">
            <p onClick={() => window.open('mailto:diana.pinheiro@arvorecoop.pt')}>
              email: diana.pinheiro@arvorecoop.pt
            </p>{' '}
            {'\t'}
            <p
              onClick={() => {
                if (mobile) window.open('tel:222076010');
              }}
              style={{ cursor: 'auto' }}
            >
              tel: 222 07 60 10
            </p>
          </div>
        </div>
      );
    }
  }
}
